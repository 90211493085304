@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: local("Poppins Regular"), local("Poppins-Regular"),
    url("assets//fonts/Poppins-Regular.ttf") format("woff2");
  font-display: swap;
}

:root {
  --gcc-remit-green: #03a552;
  --gcc-remit-blue: #2a339a;
  --gcc-remit-regular-white: #ffffff;
  --gcc-remit-gray: #696969;
  --gcc-remit-black: #000000;
  --gcc-remit-paragraph: #717171;
  --gcc-remit-font: "Poppins", sans-serif;
  --gcc-remit-light-blue: #6c90b9;
  --gcc-remit-dark-blue: #002249;
  --gcc-remit-red: #ed3c0d;
  --gcc-remit-dark-blue: #002249;
  --gcc-remit-section-gray: #f5f5f5;
  --gcc-remit-dark-gray: #3a3a3a;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

a {
  text-decoration: none !important;
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: var(--gcc-remit-font) !important;
}

body {
  font-family: var(--gcc-remit-font) !important;
  overflow-x: hidden;
}

ul {
  list-style: none;
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
.section-primary {
  background-color: var(--gcc-remit-blue);
  color: var(--gcc-remit-regular-white);
}

.section-grey-bg {
  background-color: var(--gcc-remit-section-gray);
}

.section-header {
  text-align: center;
  padding: 50px 0;
}

.section-header h1 {
  font-size: 32px;
  font-weight: 600;
  padding-bottom: 20px;
  position: relative;
}

.section-header h1:after {
  content: "";
  position: absolute;
  display: block;
  width: 15rem;
  height: 5px;
  background: var(--gcc-remit-green);
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

/*--------------------------------------------------------------
# Other General
--------------------------------------------------------------*/

.bluebutton {
  padding: 5px 20px;
  color: var(--gcc-remit-regular-white);
  background-color: var(--gcc-remit-green);
  border: none;
  font-weight: bold;
  height: unset !important;
  font-size: 18px;
  transition: 0.3s;
  border-radius: 7px;
}

@media (min-width: 991px) {
  .hovereffect {
    background-image: linear-gradient(
      to right,
      var(--gcc-remit-green),
      var(--gcc-remit-green) 50%,
      var(--gcc-remit-black) 50%
    );
    background-size: 200% 100%;
    background-position: -100%;
    display: inline-block;
    padding: 0;
    position: relative;
    -webkit-background-clip: text;
    transition: all 0.3s ease-in-out;
  }
  .hovereffect:hover {
    background-position: 0;
  }
  .hovereffect:before {
    content: "";
    background: var(--gcc-remit-blue);
    display: block;
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 0;
    height: 5px;
    transition: all 0.3s ease-in-out;
  }
  .hovereffect:hover::before {
    width: 100%;
  }
}

.main-btn {
  line-height: 50px;
  font-size: 18px;
  font-weight: 700;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  padding: 0 14px 0px 21px;
  background-color: var(--gcc-remit-green);
  color: var(--gcc-remit-regular-white);
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 2;
}

.main-btn::after {
  position: absolute;
  right: -2px;
  top: 0px;
  bottom: 4px;
  background-color: var(--gcc-remit-blue);
  transition: all 0.3s ease-out 0s;
  content: "";
  z-index: -1;
  width: 50px;
  height: 50px;
  border-radius: 5px;
}

.main-btn:hover {
  color: var(--gcc-remit-regular-white);
}

.main-btn:hover::after {
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
}

.righticon {
  padding-left: 40px;
  color: var(--gcc-remit-regular-white);
}

a:focus,
input:focus,
textarea:focus,
button:focus,
.slick-initialized .slick-slide:focus,
.btn:focus,
select:focus {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

input,
textarea,
button,
select {
  border: none;
}

input,
textarea,
select {
  width: 100%;
  background-color: var(--gcc-remit-regular-white);
  padding: 0 25px;
  height: 50px;
  font-weight: 700;
  font-size: 15px;
  color: var(--gcc-remit-black);
}

textarea {
  height: 140px;
  padding-top: 20px;
  resize: none;
}

::-webkit-input-placeholder {
  opacity: 1;
}

:-ms-input-placeholder {
  opacity: 1;
}

::-ms-input-placeholder {
  opacity: 1;
}

::placeholder {
  opacity: 1;
}

.input-group {
  position: relative;
}

.input-group input,
.input-group textarea,
.input-group select {
  padding-right: 50px;
}

.input-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.input-group select::-ms-expand {
  display: none;
}

.input-group .icon {
  position: absolute;
  right: 25px;
  font-size: 25px;
  color: var(--gcc-remit-paragraph);
  top: 50%;
  transform: translateY(-50%);
}

.input-group.textarea .icon {
  top: 20px;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

@media only screen and (min-device-width: 991px) and (max-device-width: 1367px) {
  .bluebutton {
    height: unset !important;
    font-size: 14px;
  }
}

.cookie-class {
  display: flex !important;
  justify-content: center;
}

.modal-dialog {
  position: absolute !important;
  bottom: -28px !important;
  max-width: 1920px !important;
  width: 100%;
}

.modal-content {
  padding: 10px !important;
  border-radius: 10px !important;
}

.cookie-accept-btn {
  background-color: var(--gcc-remit-dark-blue);
  border: none;
}

.reject-cookie-btn {
  background-color: var(--gcc-remit-gray);
  border: none;
}

.cookie-accept-btn:hover {
  background-color: var(--gcc-remit-blue);
  border: none;
}

.slick-dots li button:before {
  font-size: 15px !important;
  color: var(--gcc-remit-blue) !important;
  opacity: unset !important;
}

.slick-dots li.slick-active button {
  border: 2px solid var(--gcc-remit-blue) !important;
  height: 26px;
  border-radius: 50%;
  width: 26px;
}

.slick-dots li.slick-active button:before {
  color: var(--gcc-remit-green) !important;
  margin-top: 4px;
  margin-left: 3px;
}
